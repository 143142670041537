<template>
  <div class="container">
    <Breadcrumb />
    <ComponentExample />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import ComponentExample from "@/components/_ComponentExample";

export default {
  metaInfo() {
    return {
      title: "Feedback - Ivy-Way Academy"
    };
  },

  components: {
    ComponentExample,
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>
